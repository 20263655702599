import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';


@Injectable()
export class ApiService {

  domain: string;
  protocol: string;

  constructor(private _http_client: HttpClient) {

    this.domain = environment.domain;
    this.protocol = environment.protocol;

  }


  post(url: string, data: any) {
    return this._http_client.post(this.protocol + this.domain + '/api/v1/' + url,
      data,
    );
  }


  get(url: string, data: any) {
    return this._http_client.get(this.protocol + this.domain + '/api/v1/' + url, {
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }));


  }


}

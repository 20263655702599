import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  showIframe: boolean = false;
  iframeURL: any;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera";

    const elements = document.querySelectorAll('div.day');
    elements.forEach(e => {
      e.addEventListener('click', () => {
        var section = document.getElementById("iframe");
        section!.style.display = "flex";
        section!.scrollIntoView({behavior: "smooth"});
      });
    });
  }

  widgetUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL);
  }

  changeIframe(value: any) {
    this.showIframe = true;
    if(value === 0) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera";
    } else if(value === 1) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-5-octubre";
    } else if(value === 2) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-7-octubre";
    } else if(value === 3) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-8-octubre";
    } else if(value === 4) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-9-octubre";
    } else if(value === 5) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-10-octubre";
    } else if(value === 6) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-11-octubre";
    } else if(value === 7) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-12-octubre";
    } else if(value === 8) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-18-octubre";
    } else if(value === 9) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-20-octubre";
    } else if(value === 10) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-6-octubre";
    } else if(value === 11) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-13-octubre";
    } else if(value === 12) {
      this.iframeURL = "https://www.wegow.com/es/widget/oktoberfest-valdespartera-19-octubre";
    }
  }
}

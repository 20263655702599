<div class="main-container">
  <div style="display: flex; justify-content: center; align-items: center; margin-top: 50px; font-size: 22px; font-weight: bold;">
    <img class="logo" style="max-width: 42rem;" src="assets/OktoberFest-white.png" alt="OKTOBERFEST VALDESPARTERA">
  </div>
  <div style="display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 30px; padding: 50px 20px;">
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
    (click)="changeIframe(0)" class="day">
      <div>VIE.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">04 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(1)" class="day">
      <div>SÁB.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">05 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(10)" class="day">
      <div>DOM.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">06 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(2)" class="day">
      <div>LUN.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">07 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(3)" class="day">
      <div>MAR.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">08 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(4)" class="day">
      <div>MIÉ.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">09 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(5)" class="day">
      <div>JUE.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">10 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(6)" class="day">
      <div>VIE.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">11 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(7)" class="day">
      <div>SÁB.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">12 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(11)" class="day">
      <div>DOM.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">13 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(8)" class="day">
      <div>VIE.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">18 OCT.</div>
      <div>2024</div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; background-color: rgb(34, 115, 182);
    border: 2px solid rgb(255, 255, 255); font-weight: bold; border-radius: 5px; padding: 15px; cursor: pointer;"
        (click)="changeIframe(12)" class="day">
      <div>SÁB.</div>
      <div class="day-number" style="font-size: 20px; padding: 8px 0;">19 OCT.</div>
      <div>2024</div>
    </div>
  </div>
  <div style="font-size: 12px; color: #fff; text-align: center; padding-bottom: 100px;">
    *Gastos de gestión por cada entrada: 2€
  </div>
</div>

<div *ngIf="showIframe === true" style="height: 100%; margin-top: 300px" id="iframe">
  <iframe [src]='widgetUrl()' name="myIFrame" width="100%" height="100%"></iframe>
</div>
